import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import '../sass/layout/piece.scss';

export default function Template({ data }) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const {
    frontmatter: { title, subtitle, date, img },
    html
  } = markdownRemark;

  return (
    <div className="wrapper-main layout-piece">
      <div>
      { img ? <Img sizes={img.childImageSharp.fluid} /> : null}
      </div>
      <div>
        <h1>{title}</h1>
        <h3>{subtitle}</h3>
        <h2>{date}</h2>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
  );
}

export const pieceQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        img {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        materials
        subtitle
        date(formatString: "YYYY")
      }
    }  
  }
`;
